export const basePx = 16
export const pxToRem = (px: number) => `${px / basePx}rem`
export const deviceSize = {
  tablet: 580,
  tabletXL: 991,
  laptop: 1280,
  desktop: 1540,
}
const monrope = 'Manrope, sans-serif '
const amatic = 'Amatic SC, sans-serif'
const sora = 'Sora, sans-serif'
export const font = {
  monrope: monrope,
  amatic: amatic,
  sora: sora,
}
export default {
  pxToRem,
  size: {
    tablet: `${deviceSize.tablet}px`,
    tabletXL: `${deviceSize.tabletXL}px`,
    laptop: `${deviceSize.laptop}px`,
    desktop: `${deviceSize.desktop}px`,
  },
  device: {
    tablet: `screen and (min-width: ${deviceSize.tablet + 1}px)`,
    tabletXL: `screen and (min-width: ${deviceSize.tabletXL + 1}px)`,
    laptop: `screen and (min-width: ${deviceSize.laptop + 1}px)`,
    desktop: `screen and (min-width: ${deviceSize.desktop + 1}px)`,
  },
  color: {
    primary: '#727856',
    textPrimary: '#1D1D1D',
    textSecondary: '#505050',
    white: '#FFFFFF',
    black: '#000000',
    textHover: '#BBBBBB',
    sageGreenLight: '#99A98C',
    sageGreenDark: '#727856',
  },
  font: font,
  globalPadding: {
    topMobile: '95px',
    topTabletXL: '95px',
    mobileLeftRight: '16px',
    tabletLeftRight: '36px',
    tabletXLLeftRight: '78px',
    laptopLeftRight: '156px',
  },
}
