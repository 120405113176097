import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of the context's value
interface EmailContextType {
    email: string;
    setEmail: (email: string) => void;
}

// Create the context with a default undefined value
const EmailContext = createContext<EmailContextType | undefined>(undefined);

// Custom hook to use the context
export const useEmail = () => {
    const context = useContext(EmailContext);
    if (context === undefined) {
        throw new Error('useEmail must be used within a EmailProvider');
    }
    return context;
};

// Define the type for the provider's props
interface EmailProviderProps {
    children: ReactNode;
}

// Provider component
export const EmailProvider: React.FC<EmailProviderProps> = ({ children }) => {
    const [email, setEmail] = useState<string>("");

    // Value object that we'll pass to the provider
    const value = { email, setEmail };

    return (
        <EmailContext.Provider value={value}>
            {children}
        </EmailContext.Provider>
    );
};
