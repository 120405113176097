import { createGlobalStyle } from 'styled-components'
import '../../static/fonts/fonts.css'
import { font } from './variables'
import { normalize } from 'styled-normalize'
import reset from 'styled-reset'

export const GlobalStyle = createGlobalStyle`
    ${reset}
    ${normalize}
  html {
    font-family: ${font.monrope};
    margin: 0;
    padding: 0;
  }`
