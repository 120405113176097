import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { GlobalStyle } from './src/styles/globalstyle'
import { EmailProvider } from './src/context/EmailContext'



export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
    return (
        <EmailProvider>
            <GlobalStyle />
            {element}
        </EmailProvider>
    )
}